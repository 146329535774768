import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import Edit from 'src/components/icons/Edit'
import SignOut from 'src/components/icons/SignOut'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function NavBar({ user }) {
    const { t } = useTranslation();
    const { forgetProfile } = useContext(ProfileContext);
    const { studentAccount, editStudentAccount, findTeacher } = useLocalizedRoutes();
    const LINK = 'block py-2 md:py-3 px-4 lg:px-10 border-l-4 border-white font-semibold md:w-full md:w-auto';
    const ACTIVE = 'border-dark-blue-600 text-dark-blue-600';

    return (
        <div className="w-full lg:w-4/12 lg:pr-12 xl:pr-20 lg:sticky" style={{ top: '120px' }}>
            <div className="border border-grey-300 rounded mb-6 lg:mb-0 py-4 lg:py-0 md:flex lg:block">
                <div className="px-4 pb-2 lg:p-4 md:p-10 md:border-r lg:border-r-0 lg:border-b border-grey-300 md:w-1/2 lg:w-full">
                    <h2 className="font-titles text-2xl lg:text-3xl font-bold text-dark mb-4 md:mb-0">{user.first_name}</h2>
                    <Link
                        to={editStudentAccount}
                        className="inline-flex items-center text-sm text-dark-blue-600"
                    >
                        <Edit />
                        <span className="pl-2 font-semibold">{t('student.profile.nav.edit')}</span>
                    </Link>
                </div>
                <div className="pt-0 lg:py-8 md:w-1/2 lg:w-full md:flex md:items-center flex-wrap">
                    {user.email_verified_at &&
                    <>
                        <Link
                            className={LINK}
                            activeClassName={ACTIVE}
                            to={studentAccount}>{t('student.profile.nav.upcoming')}</Link>
                        <Link
                            className={LINK}
                            activeClassName={ACTIVE}
                            to={findTeacher}>{t('student.profile.nav.book')}</Link>
                    </>
                    }
                    <div
                        className="lg:hidden text-grey-500 font-semibold inline-flex items-center pl-5 mt-2"
                        onClick={forgetProfile}
                    >
                        <SignOut/>
                        <span className="pl-2">{t('student.profile.nav.signout')}</span>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block p-4 md:py-6 md:px-10 cursor-pointer" onClick={forgetProfile}>
                <div
                    className="text-grey-500 font-semibold inline-flex items-center pl-1"
                >
                    <SignOut />
                    <span className="pl-2">{t('student.profile.nav.signout')}</span>
                </div>
            </div>
        </div>
    )
}
