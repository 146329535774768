import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Context as ThemeContext} from 'src/context/ThemeContext'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import { useQueryParam, StringParam } from 'use-query-params'
import {useVisitorApi} from 'src/hooks/useApi'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import usePrivateRoute from 'src/hooks/usePrivateRoute'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import NavBar from 'src/components/sections/student/NavBar'
import EditStudentForm from 'src/components/profile/EditStudentForm'
import PrimaryButton, {SubmitButton} from 'src/components/buttons/Primary'
import MailingPreferences from 'src/components/profile/MailingPreferences'
import Modal from 'src/components/modal/Modal'
import EditStudentDetails from 'src/components/sections/student/EditStudentDetails'
import Success from 'src/components/forms/Success'
import Error from 'src/components/forms/Error'


export default function EditStudentAccount(props) {
    const {t} = useTranslation();
    const {location} = props;
    const [modalActive, setModalActive] = useState(false);
    const [changesSaved, setChangesSaved] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token] = useQueryParam("verification_token", StringParam);
    const {state: {profile }} = useContext(ProfileContext);
    const {setTheme} = useContext(ThemeContext);
    const {editStudentAccount} = useLocalizedRoutes();
    const {resendVerification, verifyEmail} = useVisitorApi()
    const [successMsg, setSuccessMsg] = useState(false);

    usePrivateRoute();
    useEffect(() => setTheme('inverse'), []);

    useEffect(() => {
        if(token) {
            verifyEmail({verificationToken: token, email: profile.email, password: profile.password })
        }
    }, [])

    useEffect(() => {
        return () => {
            if (!changesSaved) {
                setModalActive(true)
            }
        }
    }, [changesSaved])

    function checkIfEmailWasVerified() {
        if (emailSent)
              return  <Success className="">{t('general.verifyEmail', {email: profile.email})}</Success>
        if (!profile.email_verified_at && !error)
        return <SubmitButton onClick={resendEmailVerification} isLoading={loading}>{t('student.addDetails.verify.button')}</SubmitButton>
        if (error)
            return <Error>{error}</Error>
    }

    async function resendEmailVerification() {
        setLoading(true)
        const redirectUrl = location.origin + editStudentAccount;
        const res = await resendVerification(redirectUrl);
        if (res.status === 200) {
            setEmailSent(true)
        }
        else setError(Object.values(res.errors).toString());
        setLoading(false)
    }

    function onCompletion() {
        setSuccessMsg(true)
        setTimeout(() => setSuccessMsg(false), 3000)
    }

    if (profile) {
        return (
            <MarketingLayout {...props} title={profile.first_name}>
                <div className="px-4">
                    <div className="container mx-auto py-8 lg:py-12 flex items-start flex-wrap">
                        <NavBar
                            user={profile}
                        />
                        <div className="w-full lg:w-8/12">
                            <div className="flex flex-wrap items-end justify-between w-full mb-8 relative">
                                <h2 className="w-full md:w-auto font-titles font-bold text-dark text-2xl lg:text-4xl">{t('student.edit.header.title')}</h2>
                                {checkIfEmailWasVerified()}
                            </div>
                            {profile.email_verified_at ?
                            <div className="lg:w-10/12">
                                <EditStudentForm onCompletion={onCompletion} formId="edit-student"/>
                                {/*<div className="mt-12 mb-8">
                                    <EditPasswordForm/>
                                </div>*/}
                                <div className="mt-12 mb-8">
                                    <EditStudentDetails/>
                                </div>
                                {/*<div className="mt-12 mb-8">
                                    <MailingPreferences/>
                                </div>*/}
                            </div> :
                            <div className="lg:w-10/12">
                                <p>{t('student.addDetails.verify.text')}</p>
                            </div>
                            }
                        </div>
                    </div>
                </div>

                {successMsg && <Success>{t('teacher.addDetails.detailsSaved')}</Success>}

                {/* only show it when it's needed
                <Modal active={modalActive} onClickClose={() => setModalActive(!modalActive)}
                       className="max-w-lg lg:p-16">
                    <h2 className="text-4xl font-titles font-bold">Continue without saving?</h2>
                    <p className="mt-6 mb-10 text-grey-600">You will lose the changes you have made to your profile.</p>
                    <PrimaryButton className="w-full">{'Save changes'}</PrimaryButton>
                </Modal>*/}
            </MarketingLayout>
        )
    }

    return null
}
