import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import { Context as ProfileContext } from 'src/context/ProfileContext'

export default function usePrivateRoute() {
   const { state: { accessToken } } = useContext(ProfileContext);
   const { home } = useLocalizedRoutes();

   useEffect(() => {
       if(!accessToken) navigate(home)
   }, [accessToken])
}