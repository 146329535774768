import React, {  useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useForm from 'src/hooks/useForm'
import { useVisitorApi } from 'src/hooks/useApi'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import usePreferences from 'src/hooks/usePreferences'
import Input, { DateInput } from 'src/components/forms/Input'
import Select from 'src/components/forms/Select'
import { countries } from 'src/util/countries'
import { usePageContext } from 'src/context/PageContext'
import {fromDateFormat} from "../../api";
import {SubmitButton} from "../buttons/Primary";
import useLocalizedRoutes from "../../hooks/useLocalizedRoutes";
import { useLocation } from '@reach/router'

export default function EditStudentForm({ formId, onCompletion }) {
    const { t, i18n } = useTranslation();
    const { dateFormat } = usePreferences();
    const { state: { profile } } = useContext(ProfileContext);
    const { convertToDateFormat, editUser } = useVisitorApi();
    const location = useLocation();
    const {translatedInstruments, translatedExperienceLevel, translatedLanguages} = usePageContext();
    const [defaultInst, setDefaultInst] = useState(translatedInstruments.find(inst => inst.value === profile.student_profile.instrument?.id));
    const [defaultExp, setDefaultExp] = useState(translatedExperienceLevel.find(exp => exp.value === profile.student_profile.experience_level));
    const [defaultLang, setDefaultLang] = useState(translatedLanguages.find(lang => lang.value === profile.locale));
    const [defaultCountry, setDefaultCountry] = useState(countries.find(country => country.value === profile.country_code));
    const { studentAccount } = useLocalizedRoutes();

    const initialValues = {
        first_name: profile.first_name,
        last_name: profile.last_name,
        email: profile.email,
        phone: profile.phone,
        date_of_birth: fromDateFormat(profile.date_of_birth).toFormat(dateFormat),
        country_code: profile.country_code,
        postal_code: profile.postal_code
    };

    const {
        values,
        setValue,
        onChangeValue,
        onChangeValueByName,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: onCompletion,
        validationMessages: t('student.register.globalError'),
    });

    const ROW = 'flex items-center flex-wrap py-2 -mx-2 lg:-mx-4';
    const COL = 'px-4 w-full md:w-6/12 mt-4';

    async function onSubmit(values) {
        values.locale = profile.locale;
        values.redirect_url = location.origin + studentAccount;
        delete values.profile_picture;
        return await editUser({
            ...values,
            date_of_birth: convertToDateFormat(values.date_of_birth, dateFormat),
        });
    }

    return (
        <form
            id={formId}
            className="w-full md:w-25"
            onSubmit={submit}
        >
            <div className="flex flex-wrap items-start -mx-4">
                <div className={COL}>
                    <Input
                        isRequired
                        name="first_name"
                        value={values.first_name}
                        error={errors.first_name}
                        label={t('general.forms.firstName')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="last_name"
                        value={values.last_name}
                        error={errors.last_name}
                        label={t('general.forms.lastName')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="email"
                        isDisabled={true}
                        value={values.email}
                        error={errors.email}
                        label={t('general.forms.email')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="phone"
                        value={values.phone}
                        error={errors.phone}
                        label={t('general.forms.phone')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <DateInput
                        isRequired
                        name="date_of_birth"
                        value={values.date_of_birth}
                        error={errors.date_of_birth}
                        label={t('general.forms.dateOfBirth')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Select
                        key={0}
                        isRequired
                        isFormSelect
                        id="country_code"
                        name="country_code"
                        defaultValue={defaultCountry}
                        data={countries}
                        label={t('general.forms.country')}
                        placeholder={t('general.forms.countryPlaceholder')}
                        onChange={(item) => setValue('country_code', item.value)}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="postalCode"
                        value={values.postal_code}
                        error={errors.postal_code}
                        label={t('general.forms.postalCode')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Select
                        key={1}
                        isRequired
                        isFormSelect
                        id="locale"
                        name="locale"
                        defaultValue={defaultLang}
                        data={translatedLanguages}
                        label={t('general.forms.language')}
                        placeholder={t('general.forms.languagePlaceholder')}
                        onChange={(lang) => setValue('locale', lang.value)}
                    />
                </div>
                <div className={COL}>
                    <SubmitButton
                        isLoading={isLoading}
                    >
                        {t('student.edit.header.save')}
                    </SubmitButton>
                </div>
            </div>
        </form>
    )
}


