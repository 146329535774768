import React, {useContext, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {usePageContext} from 'src/context/PageContext'
import RadioGroup from 'src/components/forms/RadioGroup'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import useForm from 'src/hooks/useForm'
import {useVisitorApi} from 'src/hooks/useApi'
import Select from 'src/components/forms/Select'
import Heading from 'src/components/typography/Heading';
import Paragraph from 'src/components/typography/Paragraph';
import {SubmitButton} from 'src/components/buttons/Primary';
import Success from 'src/components/forms/Success'
import Error from 'src/components/forms/Error'

const ROW = 'flex items-center flex-wrap py-2 -mx-2 lg:-mx-4';
const COL = 'px-2 lg:px-4 w-full md:w-6/12 mt-4';

export default function EditStudentDetail() {
    const {t} = useTranslation();
    const {state: {profile: {student_profile}}} = useContext(ProfileContext);
    const {translatedInstruments, translatedExperienceLevel} = usePageContext();
    const [defaultInst, setDefaultInst] = useState(null);
    const [defaultExp, setDefaultExp] = useState(null);
    const {addStudentDetails} = useVisitorApi();
    const [success, setSuccess] = useState(false);
    const [loadingInst, setLoadingInst] = useState(true);
    const [loadingExp, setLoadingExp] = useState(true);

    useEffect(() => {
        const instrumentFound = translatedInstruments.find(({value}) => value === student_profile.instrument?.id);
        if(instrumentFound) {
            setDefaultInst(instrumentFound);
        }
        setLoadingInst(false)
    }, [translatedInstruments]);

    useEffect(() => {
        setDefaultExp(translatedExperienceLevel.find(exp => exp.value === student_profile.experience_level))
        setLoadingExp(false)
    }, [translatedExperienceLevel]);

    const initialValues = {
        experience_level: student_profile.experience_level,
        instrument: student_profile.instrument,
        instrument_disposal: student_profile.instrument_disposal
    };

    const radioOptions = [
        {
            id: 1,
            value: 'owned',
            label: t('student.addDetails.haveAnInstrument.option1')
        },
        {
            id: 2,
            value: 'none',
            label: t('student.addDetails.haveAnInstrument.option2')
        },
    ];

    const {
        values,
        setValue,
        onChangeValue,
        onChangeValueByName,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: onRegistrationCompleted,
        validationMessages: t('student.register.globalError'),
    });


    async function onSubmit(values) {
        if(values.instrument instanceof Object) {
            values.instrument = values.instrument.id
        }
        return await addStudentDetails({
            ...values,
        });
    }

    function onRegistrationCompleted() {
        setSuccess(true)
        setTimeout(() => setSuccess(false), 3000);
    }


    return (
        <>
            <form
                className="w-full md:w-25 lg:px-0 mt-4 md:mb-8 lg:my-10"
                onSubmit={submit}>
                <h3 className="font-titles font-bold text-2xl lg:text-3xl">Student details</h3>
                <div className={ROW}>
                    <div className={COL}>
                        {!loadingInst &&
                        <Select
                            key={2}
                            isRequired
                            isFormSelect
                            id="instruments"
                            name="instruments"
                            defaultValue={defaultInst}
                            data={translatedInstruments}
                            label={t('student.addDetails.instrument')}
                            onChange={(item) => {

                                setValue('instrument', item.value)
                            }}
                        />
                        }
                    </div>
                    <div className={COL}>
                        {!loadingExp &&
                        <Select
                            key={3}
                            isRequired
                            isFormSelect
                            id="experience"
                            name="experience"
                            defaultValue={defaultExp}
                            data={translatedExperienceLevel}
                            label={t('student.addDetails.experienceLevel')}
                            onChange={(item) => setValue('experience_level', item.value)}
                        />
                        }
                    </div>
                </div>
                <div className="mt-12">
                    <Heading type="caption">{t('student.addDetails.haveAnInstrument.title')}</Heading>
                    <Paragraph className="mt-4">{t('student.addDetails.haveAnInstrument.body')}</Paragraph>
                    <RadioGroup
                        defaultValue={student_profile.instrument_disposal}
                        name={'form-name'}
                        items={radioOptions}
                        onChange={(item) => setValue('instrument_disposal', item.target.value)}
                    />
                </div>
                <div className={ROW}>
                    <div className="px-2 lg:px-4 mt-8">
                        <SubmitButton
                            isLoading={isLoading}
                        >
                            Save
                        </SubmitButton>
                    </div>
                </div>
            </form>
            {errors && <Error>{Object.values(errors).toString()}</Error>}
            {success && <Success>Your changes have been saved.</Success>}
        </>
    )
}
